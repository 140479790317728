import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Navbar,
  Nav,
  Badge,
  NavDropdown,
  Container,
} from "react-bootstrap";

import { Linkedin } from "react-bootstrap-icons";
import TwitterX from './twitter-x.svg';

import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import logo from "./logo.png";
import Login from "./Login";

const Menu = () => {
  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo} height="50" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="mr-auto mx-3">
            <Nav.Link href="https://www.linkedin.com/company/matchittech/"><Linkedin /></Nav.Link>
          </Nav>
          <Nav className="mr-auto mx-3">
            <Nav.Link href="https://twitter.com/MatchIT_guru"><img src={TwitterX} alt="twitter-x" /></Nav.Link>
          </Nav>
          <Nav className="mr-auto mx-5">
            <Login />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
