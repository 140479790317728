import { createSlice } from "@reduxjs/toolkit";

export const sliceAuthModal = createSlice({
  name: "authModal",
  initialState: {
    value: false,
  },
  reducers: {
    actionShow: (state) => {
      console.log("show modal");
      state.value = true;
    },
    actionHide: (state) => {
      console.log("hide modal");
      state.value = false;
    },
  },
});

export const { actionShow, actionHide } = sliceAuthModal.actions;
export const authModalStateGetter = (state) => {
  return state.authModal.value;
};
export default sliceAuthModal.reducer;
