import React from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Footer = () => {
  console.log("Footer");
  return (
    <Navbar className="py-2 bg-light">
      <Container>
        <Row>
          <Col>
            <a className="linkSuccess" href="/privacy-policy">
              Privacy Policy
            </a>
          </Col>
          <Col>
            <a className="linkSuccess" href="/terms-of-service">
              Terms of Service
            </a>
          </Col>
          <Col>
            <a className="linkSuccess" href="/mention-legale">
              Legal Notice
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p>
              &copy; {new Date().getFullYear()} MatchIT. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
