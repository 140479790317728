import { createSlice } from "@reduxjs/toolkit";

export const sliceProfile = createSlice({
  name: "profile",
  initialState: {
    value: { edit: false },
  },
  reducers: {
    actionEdit: (state) => {
      state.value = { edit: true };
    },
    actionPreview: (state) => {
      state.value = { edit: false };
    },
  },
});

export const { actionEdit, actionPreview } = sliceProfile.actions;
export const profileStateGetter = (state) => {
  return state.profile.value;
};
export default sliceProfile.reducer;
