import React from "react";
import Menu from "./Menu";
import ProductPage from "./ProductPage";
import Login from "./Login";
import Footer from "./Footer";
import Profile from "./Profile";
import Responses from "./Responses";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Provider } from "react-redux";
import Account from "./Account";
import store from "./store";
import DocMarkdown from "./DocMarkdown";
import Feedback from "./Feedback";
const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <Menu />
          <Routes>
            <Route index path="/" element={<ProductPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/responses" element={<Responses />} />
            <Route
              path="/privacy-policy"
              element={<DocMarkdown fileName="privacy" />}
            />
            <Route
              path="/terms-of-service"
              element={<DocMarkdown fileName="termsofservices" />}
            />
            <Route
              path="/mention-legale"
              element={<DocMarkdown fileName="mentionlegale" />}
            />
            <Route path="/account" element={<Account />} />
            <Route path="*" element={<Profile />} />
          </Routes>
        </div>
        <div className="mh-100"></div>
        <Footer />
      </BrowserRouter>
      <Feedback />
    </Provider>
  );
};

export default App;
