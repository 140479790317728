import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Alert,
  Table,
  Modal,
  Spinner,
  Tabs,
  Tab,
  InputGroup,
  SplitButton,
  Form,
} from "react-bootstrap";
import Markdown from "marked-react";
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./store";
import { getProfileId } from "./utils.js";
import ContactForm from "./ContactForm.js";
import { loginStateGetter } from "./SliceLogin";
import CV2 from "./CV2";
import { actionEdit, actionPreview, profileStateGetter } from "./SliceProfile";
import { BellFill, Bell, BellSlash, Share } from "react-bootstrap-icons";
const Profile = (props) => {
  const [isOwner, setIsOwner] = useState(false);
  const [configured, setConfigured] = useState(false);
  const loginState = useSelector(loginStateGetter);
  const profileId = getProfileId();
  const dispatch = useDispatch();
  const profileState = useSelector(profileStateGetter);

  console.log("Building Profile", profileId, loginState);

  const tabsEditPreview = () => {
    const activeKey = profileState.edit === false ? "Preview" : "Edit";
    return (
      <Tabs
        className="fixed"
        defaultActiveKey="Preview"
        activeKey={activeKey}
        onSelect={(k) => {
          console.log("selecting tab", k);
          if (k == "Edit") {
            dispatch(actionEdit());
          } else {
            dispatch(actionPreview());
          }
        }}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab id="tab-preview" eventKey="Preview" title="Preview"></Tab>
        <Tab id="tab-edit" eventKey="Edit" title="Edit"></Tab>
      </Tabs>
    );
  };

  useEffect(() => {
    if (loginState.state === "connected") {
      console.log("Profile loginState", loginState);
      setIsOwner(
        profileId ==
          loginState.value.payload.signInUserSession.idToken.payload[
            "custom:profileId"
          ]
      );
    }
  }, [loginState]);
  const profileUrl = "https://" + window.location.hostname + "/" + profileId;
  return (
    <>
      {loginState.state === "unknown" ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <>
          <Container className="py-5">
            {isOwner && (
              <div className="mt-3">
                <h2>Hey there! 🙌</h2>
                <InputGroup className="mb-3">
                  <Form.Control type="text" value={profileUrl} readOnly />
                  <Button
                    variant="success"
                    title="copy"
                    id="segmented-button-dropdown-2"
                    alignRight
                    onClick={() => {
                      navigator.clipboard.writeText(profileUrl);
                    }}
                  >
                    Copy
                  </Button>
                </InputGroup>
                <Form.Text className="text-muted">
                  You can share this link, to share your profile.
                </Form.Text>

                <p>
                  Ready to make your resumé pop? You're in the right place! On
                  this page, you can add some spicy questions for your
                  recruiter. Don't worry; we've got you covered with options.
                </p>
                <p>Happy crafting!</p>
              </div>
            )}
            <div className="mt-5"></div>
            {isOwner && (
              <>
                <h2>This is who you are!</h2>
                <p>
                  Here you can upload your best CV to tell a bit about you ;)
                </p>
              </>
            )}
            <CV2
              profileId={profileId}
              isOwner={isOwner}
              className="w-100 py-5"
            />
            <div className="mt-5"></div>
            {isOwner && (
              <>
                <h2>This is what you want!</h2>
                <p>
                  With matchIT, you have the power to tailor your experience.
                  You can create your own form with questions that are
                  significant to you. Your form won't be shown to others, but it
                  will enable you to filter responses to focus on what's truly
                  important.
                </p>
                <ul>
                  <li>
                    <strong>
                      Mandatory: <BellFill />
                    </strong>
                    These are the deal breaker! If a recruiter answer below your
                    expectation to these questions, their message goes straight
                    to the virtual trash. For example, you only want the offers
                    that are over 9000$$, make it mandatory!
                  </li>
                  <li>
                    <strong>
                      Optional: <Bell />
                    </strong>
                    You just want to know.
                  </li>
                  <li>
                    <strong>
                      Your Choice: <BellSlash />
                    </strong>
                    It's not applicable to you? No problemo! Just remove it, and
                    it's like it was never there.
                  </li>
                </ul>
              </>
            )}
            {isOwner && tabsEditPreview()}
            <ContactForm
              profileId={profileId}
              isOwner={isOwner}
              className="py-5"
            />
          </Container>
        </>
      )}
    </>
  );
};

export default Profile;
