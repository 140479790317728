import React, { useState, useEffect } from "react";
import {
  Form,
  Spinner,
  Button,
  Placeholder,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Markdown from "marked-react";
import { useSelector } from "react-redux";
import { loginStateGetter } from "./SliceLogin";
import "bootstrap/dist/css/bootstrap.min.css";
import { isOwner } from "./utils";

const displayCV = (cv) => {
  if (!cv) {
    return (
      <>
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" style={{ width: "25%" }} />
      </>
    );
  }
  console.log("CV2 displayCV", cv);

  if (cv.type == "binary/octet-stream") {
    const startText = cv.data.substring(0, 100); // get first 100 characters
    if (startText.includes("<?xml") || startText.includes("<svg")) {
      cv.type = "image/svg+xml";
    } else if (startText.charAt(0) === "#" || startText.includes("# ")) {
      cv.type = "text/markdown";
    }
  }

  console.log("CV2 displayCV2", cv.type);

  switch (cv.type) {
    case "text/markdown":
      return (
        <div id="cv-markdown">
          <Markdown>{cv.data}</Markdown>
        </div>
      );
      break;

    case "image/svg+xml":
      return (
        <>
          <div
            className="w-100"
            id="cv-svg"
            dangerouslySetInnerHTML={{ __html: cv.data }}
          ></div>
        </>
      );
      break;

    default:
      return <p>Anyone home?</p>;
  }
};

const CV2 = (props) => {
  const [file, setFile] = useState();
  const [cvData, setCvData] = useState();
  const [uploadUrl, setUploadUrl] = useState();
  const [loading, setLoading] = useState("Contacting mother ship");
  const [reload, setReload] = useState();
  // const [converterState, setConverterState] = useState({
  //   inkscape: true,
  //   libreoffice: false,
  // });
  console.log("CV2 props", props);
  const loginState = useSelector(loginStateGetter);

  useEffect(() => {
    fetch(`/data/${props.profileId}`)
      .then((response) => {
        console.log("CV2 response", response);
        return response.blob();
      })
      .then((blob) => {
        console.log("CV2 blob", blob);
        console.log("CV2 blob type", blob.type);
        blob.text().then((text) => {
          setCvData({ type: blob.type, data: text });
        });
      })
      .catch((err) => {
        console.log("CV2 load error", err);
      });

    if (!isOwner(loginState)) {
      console.log("CV2 not owner", props);
      return;
    }
    const body = {
      function: "presigned_url",
      body: {},
    };

    fetch("/api/converter", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("uploadUrl", json.url_params);
        setUploadUrl(json.url_params);
        setLoading();
        console.log("CV2 set loading false conv");
      });
  }, [reload]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    console.log("CV2 handleFileChange", e);
  };

  const handleUpload = () => {
    console.log("CV2 handleUpload", file);
    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(uploadUrl.fields)) {
      formData.append(key, value);
    }

    formData.append("file", file);

    // Perform the POST request
    console.log("CV2 fetch upload", uploadUrl, formData);
    setLoading("Teleporting file");
    console.log("CV2 set loading true upload");

    fetch(uploadUrl.url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.log("CV2 set loading false uploaded");

        if (response.status === 204) {
          setLoading("Transformation in progress");
          console.log(`CV2 Successfully uploaded ${file.name}.`);
          const body = {
            function: "convert_pdf",
            body: {
              converter: "libreoffice",
            },
          };
          fetch("/api/converter", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }).then((response) => {
            setLoading();
            setReload(new Date());
          });
        } else {
          console.log(`CV2 Failed to upload ${file.name}.`);
          alert("Upload failed :(");
          setLoading();
        }
      })
      .catch((error) => console.error("CV2 Error:", error));
  };

  const uploadComponent = () => {
    return (
      <>
        <Row className="my-3">
          <Col fluid>
            <Form.Control
              id="browse-file"
              type="file"
              accept=".pdf,.md"
              onChange={handleFileChange}
              xs="auto"
            />
          </Col>
          <Col xs="auto">
            <Button
              id="send-cv"
              variant="primary"
              onClick={handleUpload}
              disabled={Boolean(loading)}
              className="w-100"
            >
              {Boolean(loading) ? loading : "Send"}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Text className="text-muted">
              We speak pdf and markdown, more comming soon
            </Form.Text>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {isOwner(loginState) && uploadComponent()}
      {displayCV(cvData)}
    </>
  );
};

export default CV2;
