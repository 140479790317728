import { createSlice } from "@reduxjs/toolkit";

export const sliceShowResponse = createSlice({
  name: "showResponse",
  initialState: {
    value: false,
  },
  reducers: {
    actionShow: (state) => {
      console.log("show modal");
      state.value = true;
    },
    actionHide: (state) => {
      console.log("hide modal");
      state.value = false;
    },
  },
});

export const { actionShow, actionHide } = sliceShowResponse.actions;
export const showResponseStateGetter = (state) => {
  return state.showResponse.value;
};
export default sliceShowResponse.reducer;
