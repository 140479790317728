import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./SliceLogin";
import profileReducer from "./SliceProfile";
import authModalReducer from "./SliceAuthModal";
import showResponseReducer from "./SliceShowResponse";

export default configureStore({
  reducer: {
    login: loginReducer,
    profile: profileReducer,
    authModal: authModalReducer,
    showResponse: showResponseReducer,
  },
});
