import React, { useState } from "react";
import ContactForm from "./ContactForm.js";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  actionShow,
  actionHide,
  showResponseStateGetter,
} from "./SliceShowResponse";

const Response = (props) => {
  const response = props.response;
  const showState = useSelector(showResponseStateGetter);
  const dispatch = useDispatch();

  console.log("showing response", showState);
  if (!response) {
    console.log("no props reponse");
    return <></>;
  }
  console.log("display response", JSON.stringify(response));
  const s = <p></p>;
  const dateObj = new Date(1000 * response.date.value);

  const renderQuestion = (question) => {
    console.log("form creation", name, question);
    let component;
    switch (question.type) {
      case "tags":
        component = <p>{question.value.map((e) => e.text).joins(", ")}</p>;
        break;
      case "date":
        component = (
          <p>{new Date(question.value * 1000).toLocaleDateString()}</p>
        );
        break;
      case "area":
        component = (
          <Form.Control
            as="textarea"
            rows={3}
            value={question.value}
            readOnly
          />
        );
        break;
      case "checkbox":
        component = <Form.Check type="checkbox" id={question.name} />;
        break;
      case "range":
      case "location":
      default:
        component = <p>{question.value}</p>;
    }
  };

  return (
    <Modal
      size="lg"
      show={showState}
      onHide={() => {
        console.log("show false");
        dispatch(actionHide());
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div>
          <ul>
            <li>{response.mail.value}</li>
            <li>{response.name.value}</li>
            <li>{dateObj.toLocaleString()}</li>
          </ul>
          <ContactForm
            questions={response.form_data}
            readOnly={true}
            submit={s}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Response;
