import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  ListGroup,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import form0 from "./form0.svg";
import form1 from "./form1.svg";
import candidateImage from "./undraw_start_building_re_xani.svg";
import recruiterImage from "./undraw_searching_re_3ra9.svg";
import { actionShow } from "./SliceAuthModal";
import { useSelector, useDispatch } from "react-redux";

const cardStyle = {
  backgroundColor: "#effcef",
};
const headingStyle = {
  color: "#85d656",
};

const buttonStyle = {
  background: "#85d656",
  border: "none",
};

const App = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Container fluid>
        <Container className="text-center my-5">
          <h1 className="text-center mt-5 fw-bold">The Match Making for IT!</h1>
          <p className="text-center">
            It's not recruitment, it's matching people.
          </p>
        </Container>

        <Container fluid="xl">
          <Row className="for-candidates">
            <Col md={6}>
              <h3 style={headingStyle}>Looking for a new challenge?</h3>
              <Card style={cardStyle} border="light">
                <Card.Body>
                  <Card.Title>Take Control of Your Career</Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      Avoid bots with our unique verification system.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Explicitly state what you want: location, technologies,
                      environment.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      No more generic offers; focus on what matters to you.
                    </ListGroup.Item>
                  </ListGroup>
                  <Button
                    variant="primary"
                    style={buttonStyle}
                    className="mt-3"
                    onClick={() => {
                      dispatch(actionShow());
                    }}
                  >
                    Join Now – It's Free!
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Image src={candidateImage} fluid alt="candidate image"/>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Image src={recruiterImage} fluid alt="recruiter image"/>
            </Col>
            <Col md={6}>
              <h3 style={headingStyle}>Looking for your next hire?</h3>
              <Card style={cardStyle}>
                <Card.Body>
                  <Card.Title>Find the Perfect Match</Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      No more decrypting long résumés.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Connect directly with candidates who match your specific
                      needs.
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Understand candidates' goals to align with your
                      opportunities.
                    </ListGroup.Item>
                  </ListGroup>
                  <Button
                    variant="success"
                    href="#"
                      style={buttonStyle}
		      className="mt-3"
                    onClick={() => {
                      dispatch(actionShow());
                    }}
                  >
                    Register Your Company Today!
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default App;
