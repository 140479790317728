export const getProfileId = () => {
  const url = new URL(window.location);
  const profileId = url.pathname.split("/").pop();
  console.log("profileId from location", profileId.length, profileId);
  if (profileId.length !== 24) {
    return undefined;
  }
  return profileId;
};

export const isOwner = (loginState) => {
  const profileId = getProfileId();
  if (!loginState.value) {
    return false;
  }
  return (
    profileId ==
    loginState.value.payload.signInUserSession.idToken.payload[
      "custom:profileId"
    ]
  );
};

const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};
