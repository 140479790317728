import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Button,
  Accordion,
  Container,
  Badge,
} from "react-bootstrap";
import { EyeFill, BellSlash } from "react-bootstrap-icons";
import Response from "./Response";
import { useDispatch, useSelector } from "react-redux";
import { actionShow } from "./SliceShowResponse";

const Responses = () => {
  const [responses, setResponses] = useState({ accepted: [], blocked: [] });
  const [selectedResponse, setSelectedResponse] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const body = { function: "query_responses", body: {} };
    fetch("/api/formRespond", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        setResponses(json);
      });
  }, []);

  const makeTable = (rs) => {
    const lines = [
      <thead>
        <tr>
          <th>Mail</th>
          <th>Name</th>
          <th>Date</th>
          <th>Open</th>
        </tr>
      </thead>,
    ];

    for (const response of rs) {
      console.log("new line", response);
      lines.push(
        <tr>
          <td>{response.mail.value}</td>
          <td>{response.name.value}</td>
          <td>{new Date(response.date.value * 1000).toLocaleDateString()}</td>
          <td className="center-content">
            <Button variant="link">
              <EyeFill
                onClick={() => {
                  console.log("open resource", response);
                  setSelectedResponse(response);
                  dispatch(actionShow());
                }}
              />
            </Button>
          </td>
        </tr>
      );
    }

    return (
      <>
        <Table striped bordered hover>
          {lines}
        </Table>
      </>
    );
  };
  return (
    <Container className="py-5" style={{ height: "100%" }}>
      {makeTable(responses.accepted)}
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Blocked <Badge bg="secondary">{responses.blocked.length}</Badge>{" "}
          </Accordion.Header>
          <Accordion.Body>{makeTable(responses.blocked)}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Response response={selectedResponse} />
      <div className="min-vh-100"></div>
    </Container>
  );
};

export default Responses;
