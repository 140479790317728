import { createSlice } from "@reduxjs/toolkit";

export const sliceLogin = createSlice({
  name: "login",
  initialState: {
    value: { state: "unknown", value: null },
  },
  reducers: {
    actionLogin: (state, user) => {
      console.log("Hub, setting user state", user);
      state.value = { state: "connected", value: user };
    },
    actionLogout: (state) => {
      console.log("Hub, logged out");
      state.value = { state: "disconnected", value: null };
    },
  },
});

export const { actionLogin, actionLogout } = sliceLogin.actions;
export const loginStateGetter = (state) => {
  return state.login.value;
};
export default sliceLogin.reducer;
