import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import { WithContext as ReactTags } from "react-tag-input";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BellFill, Bell, BellSlash } from "react-bootstrap-icons";
import { getProfileId } from "./utils.js";
import { actionEdit, actionPreview, profileStateGetter } from "./SliceProfile";
import MyAuto from "./MyAuto";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
  ButtonGroup,
  Placeholder,
} from "react-bootstrap";
import { useDispatch } from "react-redux";

import { loginStateGetter } from "./SliceLogin";
import { useSelector } from "react-redux";

import "./react-tag.css";
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const TECHS = [
  "Spring Boot",
  "NumPy",
  "Vue.js",
  "R",
  "Python",
  "Continuous integration/continuous delivery (CI/CD) tools",
  "webDevelopment",
  "Switches",
  "Alibaba Cloud",
  "Android",
  "D3.js",
  "Personal computers",
  "Intrusion prevention systems (IPS)",
  "DNS servers",
  "Mobile development tools",
  "JavaScript",
  "Angular",
  "Perl",
  "Machine learning tools",
  "Smartphones",
  "Redis",
  "Lua",
  "CSS",
  "Elasticsearch",
  "Scikit-learn",
  "MongoDB",
  "DynamoDB",
  "Dart",
  "Amazon Web Services (AWS)",
  "Keras",
  "Moment.js",
  "Pandas",
  "Unit testing frameworks",
  "Game development tools",
  "Intrusion detection systems (IDS)",
  "Haskell",
  "PHP",
  "Version control systems (VCS)",
  "Go",
  "macOS",
  "TypeScript",
  "Rust",
  "MySQL",
  "Ruby",
  "Data encryption",
  "Web development tools",
  "Axios",
  "C ansi",
  "C99",
  "C++",
  "C++17",
  "C++20",
  "Microsoft Azure",
  "TensorFlow",
  "Tablets",
  "jQuery",
  "Antivirus software",
  "Oracle",
  "Cloud computing infrastructure",
  "Shell",
  "Objective-C",
  "iOS",
  "SciPy",
  "OpenCV",
  "React",
  "Cassandra",
  "Windows",
  "PostgreSQL",
  "Rails",
  "Servers",
  "Node.js",
  "Load balancers",
  "C#",
  "mobileDevelopment",
  "Django",
  "Kotlin",
  "Laravel",
  "Integrated development environments (IDEs)",
  "Swift",
  "Scala",
  "Artificial intelligence tools",
  "MATLAB",
  "Linux",
  "Google Cloud Platform (GCP)",
  "Firewalls",
  "Data science tools",
  "Java",
  "Matplotlib",
  "Microsoft SQL Server",
  "Lodash",
  "Routers",
  "Three.js",
  "PyTorch",
  "Laptops",
  "Groovy",
  "spaCy",
  "ASP.NET Core",
  "git",
  "gitlab",
  "github",
  "docker",
  "terraform",
  "ansible",
  "puppet",
  "salt",
  "AWS cdk",
  "AWS sam",
  "Amazon Elastic Compute Cloud (EC2)",
  "Amazon Simple Storage Service (S3)",
  "Amazon Relational Database Service (RDS)",
  "Amazon DynamoDB",
  "Amazon Simple Notification Service (SNS)",
  "Amazon Simple Queue Service (SQS)",
  "Amazon Elastic Kubernetes Service (EKS)",
  "Amazon Elastic Beanstalk",
  "Amazon Lambda",
  "Amazon CloudFront",
  "Amazon Route 53",
  "Amazon CloudWatch",
  "Amazon CloudTrail",
  "Amazon GuardDuty",
  "Amazon Inspector",
  "Amazon CloudSearch",
  "Amazon Translate",
  "Amazon Lex",
  "Amazon Polly",
  "Amazon Rekognition",
  "Amazon SageMaker",
  "App Engine",
  "Compute Engine",
  "Cloud Storage",
  "BigQuery",
  "Cloud Dataproc",
  "Cloud Dataflow",
  "Cloud Functions",
  "Cloud ML Engine",
  "Cloud Natural Language",
  "Cloud Vision API",
  "Cloud Speech-to-Text",
  "App Service",
  "Azure Compute",
  "Azure Storage",
  "Azure SQL Database",
  "Azure Cosmos DB",
  "Azure Databricks",
  "Azure Functions",
  "Azure Machine Learning",
  "Azure Cognitive Services",
  "Azure IoT Hub",
  "Azure Power BI",
];

const amenitiesPropositions = [
  "24/7 Access",
  "3D Printer",
  "ATM",
  "After Work Area",
  "Air Conditioning",
  "Arcade Games",
  "Art Supplies",
  "BBQ Area",
  "Barista Service",
  "Bike Storage",
  "Biometric Access",
  "Board Games",
  "Break Room",
  "Breakfast",
  "Cafeteria",
  "Car Wash Services",
  "Cleaning Services",
  "Coding Bootcamps",
  "Complimentary Snacks and Drinks",
  "Conference Rooms",
  "Courier Services",
  "Dental Insurance",
  "Dry Cleaning Services",
  "Employee Discounts",
  "Ergonomic Chairs",
  "Financial Planning Services",
  "Fireplace",
  "First Aid Kit",
  "Fish Tank",
  "Fitness Classes",
  "Fruits",
  "Game Room",
  "Guest Speakers",
  "Guest Waiting Area",
  "Heating System",
  "High-Speed Internet",
  "Indoor Plants",
  "Karaoke Room",
  "Kitchenette",
  "Lactation Room",
  "Language Classes",
  "Legal Consultation",
  "Library",
  "Life Insurance",
  "Lockers",
  "Lounge Area",
  "Lunch",
  "Mail Handling",
  "Massage Chairs",
  "Massages",
  "Meditation Room",
  "Mentorship Programs",
  "Microwave",
  "Music Room",
  "Nap Pods",
  "Natural Light",
  "Nutritionist Consultation",
  "On-site Childcare",
  "On-site Gym",
  "Open Workspaces",
  "Outdoor Sports Facilities",
  "Outdoor Terrace",
  "Paid Time Off",
  "Parking Space",
  "Pet Care Services",
  "Pet-Friendly Environment",
  "Phone Booths",
  "Photography Studio",
  "Pool Table",
  "Printer and Scanner",
  "Private Offices",
  "Reception Services",
  "Refrigerator",
  "Restrooms",
  "Rooftop Garden",
  "Security Cameras",
  "Sewing Machine",
  "Showers",
  "Skill Development Courses",
  "Soundproof Rooms",
  "Standing Desks",
  "Storage Space",
  "Swimming Pool",
  "Team Building Activities",
  "Tennis Table",
  "Travel Services",
  "Vending Machines",
  "Video Conferencing Equipment",
  "Vision Insurance",
  "Water Cooler",
  "Workshops",
  "Yoga Studio",
];

const jobPositions = [
  "Software Developer",
  "System Analyst",
  "Business Analyst",
  "Technical Support",
  "Network Engineer",
  "Web Developer",
  "Database Administrator",
  "Data Analyst",
  "Security Analyst",
  "Cloud Architect",
  "DevOps Engineer",
  "UX/UI Designer",
  "IT Manager",
  "Systems Administrator",
  "QA Tester",
  "Mobile App Developer",
  "AI Engineer",
  "Machine Learning Engineer",
  "Cybersecurity Specialist",
  "Technical Consultant",
  "Information Security Manager",
  "Help Desk Technician",
  "Network Administrator",
  "Application Support Analyst",
  "IT Auditor",
  "Technical Writer",
  "Software Architect",
  "Hardware Engineer",
  "Full Stack Developer",
  "Game Developer",
  "Product Manager",
  "IT Sales",
  "Data Scientist",
  "Front-end Developer",
  "Back-end Developer",
  "Data Engineer",
  "Cloud Developer",
  "Big Data Analyst",
  "Virtual Reality Developer",
  "Augmented Reality Developer",
  "SEO Specialist",
  "Content Manager",
  "Infrastructure Engineer",
  "Digital Marketing Specialist",
  "IT Compliance Analyst",
  "E-commerce Specialist",
  "Telecommunications Specialist",
  "Blockchain Developer",
  "Embedded Systems Engineer",
  "Information Research Scientist",
  "Scrum Master",
  "Agile Coach",
  "Solutions Architect",
  "Automation Engineer",
  "Computer Vision Engineer",
  "RPA Developer",
];

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const suggestions = {};
suggestions.TECHS = TECHS.map((tech) => {
  return {
    id: tech.replace(/ /g, ""),
    text: tech,
  };
});

suggestions.jobPositions = jobPositions.map((tech) => {
  return {
    id: tech.replace(/ /g, ""),
    text: tech,
  };
});

suggestions.amenitiesPropositions = amenitiesPropositions.map((tech) => {
  return {
    id: tech.replace(/ /g, ""),
    text: tech,
  };
});

const ContactForm = (props) => {
  const readOnly = props.readOnly ? true : false;
  const [validated, setValidated] = useState(false);
  const [scopesStates, setScopesState] = useState({});
  const [responseSent, setResponseSent] = useState(false);
  const [submitComponent, setSubmitComponent] = useState(<></>);
  const [questionsState, setQuestionsState] = useState(undefined);
  const [locationLoaded, setLocationLoaded] = useState(false);
  const profileState = useSelector(profileStateGetter);
  const loginState = useSelector(loginStateGetter);
  const scopes = ["required", "optional", "discarded"];
  const scopesValues = { required: 0, optional: 1, discarded: 2 };
  const dispatch = useDispatch();

  console.log("ContactForm erasing questionsState", questionsState);
  const updateState = (name, tag, value) => {
    setQuestionsState({
      ...questionsState,
      [name]: { ...questionsState[name], [tag]: value },
    });
    console.log("updateState", name, tag, value, questionsState);
  };

  const updateSubState = (name, tag, subName, value) => {
    setQuestionsState({
      ...questionsState,
      [name]: {
        ...questionsState[name],
        [tag]: {
          ...questionsState[name][tag],
          freelance: {
            ...questionsState[name][tag][subName],
            value: value,
          },
        },
      },
    });
  };

  useEffect(() => {
    fetch("/data/locations.json")
      .then((response) => response.json())
      .then((json) => {
        suggestions.locations = json.map((location) => {
          return {
            id: location.replace(/ /g, ""),
            text: location,
          };
        });
        console.log("locations", suggestions.locations);
        setLocationLoaded(true);
      });

    if (props.questions) {
      console.log("ContactForm setQuestionsState from props");
      setQuestionsState(props.questions);
      return;
    }
    const profileId = getProfileId();
    console.log("profile id", profileId);

    if (loginState.state === "connected") {
      const body = {
        function: "query_questions",
        body: { profile_id: profileId },
      };

      fetch(`/api/formRespond`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.log("resp>", response);
          return response.json();
        })
        .then((json) => {
          if (json) {
            console.log(
              "ContactForm setting questions connected>",
              json.form_data
            );
            setQuestionsState(json.form_data);
          }
        })
        .catch((err) => {
          console.log("json load", err);
        });

      return;
    }

    fetch(`/api/qs/${profileId}`)
      .then((response) => {
        console.log("resp>", response);
        return response.json();
      })
      .then((json) => {
        if (json) {
          console.log("ContactForm setting questions public>", json.form_data);
          setQuestionsState(json.form_data);
        }
      })
      .catch((err) => {
        console.log("json load", err);
        setQuestionsState(null);
      });
  }, []);

  // useEffect(() => {
  //   const formData = getValues();
  //   const scopes = ["mandatory", "optional", "discarded"];
  //   console.log(
  //     "ContactForm curing formData>",
  //     profileState.edit,
  //     formData,
  //     questionsState
  //   );
  //   if (profileState.edit === false) {
  //     for (const [key, value] of Object.entries(formData)) {
  //       if (key.endsWith("scope")) {
  //         console.log("ContactForm curing update", key, key.slice(0, -5));
  //         questionsState[key.slice(0, -5)].state = scopes[value];
  //         console.log(
  //           "ContactForm curing discard",
  //           key.slice(0, -5),
  //           scopes[value]
  //         );
  //       }
  //     }
  //     console.log("ContactForm cured", questionsState);
  //     setQuestionsState(questionsState);
  //   }
  // }, [profileState]);

  const onSubmit = (event) => {
    event.preventDefault();
    console.log("ContactForm, submit data>", event);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    setValidated(true);

    const body = {
      function: "insert_response",
      body: { profile_id: getProfileId(), form_data: questionsState },
    };

    fetch("/api/formRespond", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        setResponseSent(true);
        dispatch(actionPreview());
      })
      .catch((error) => {
        console.log("something somewhere went terribly wrong", error);
      });
  };

  const CreateLocationSearch = (question, name) => {
    if (!locationLoaded && !readOnly) {
      return <Placeholder as="p" animation="glow" style={{ width: "25%" }} />;
    }
    return CreateTags(question, name);
    // return (
    //   <MyAuto
    //     onChange={(e) => {
    //       updateState(name, "value", e.target.value);
    //     }}
    //     value={questionsState[name].value}
    //   />
    // );
  };

  const CreateArea = (question, name) => {
    return (
      <Form.Control
        as="textarea"
        rows={12}
        value={questionsState[name].value}
        onChange={(e) => {
          updateState(name, "value", e.target.value);
        }}
      />
    );
  };

  const SubmitComponent = () => {
    console.log("SubmitComponent", readOnly, responseSent, loginState.state);
    if (readOnly) {
      return <></>;
    }
    if (responseSent) {
      return (
        <Alert
          variant="success"
          dismissible
          onClose={() => {
            setResponseSent(false);
          }}
        >
          Form sent successfully, edit again to change.
        </Alert>
      );
    }
    if (loginState.state !== "connected") {
      return (
        <Alert variant="primary">
          Login to be able to contact the candidate.
        </Alert>
      );
    }

    return (
      <Button id="save-form" variant="primary" type="submit" onClick={onSubmit}>
        Save form
      </Button>
    );
    return <></>;
  };

  const CreateQuestion = (question, name, isOwner) => {
    console.log("form creation", name, question);
    let component;
    switch (question.type) {
      case "tags":
        component = CreateTags(question, name);
        break;
      case "range":
        component = CreateRange(question, name);
        break;
      case "date":
        component = CreateDatePicker(question, name);
        break;
      case "area":
        component = CreateArea(question, name);
        break;
      case "location":
        component = CreateLocationSearch(question, name);
        break;
      case "checkbox":
      case "radio":
        component = CreateCheckboxRadios(question, name);
        break;
      default:
        component = CreateInput(question, name);
    }

    return component;
  };

  const CreateTags = (question, name) => {
    if (readOnly) {
      const l = [];
      for (const tag of question.value) {
        l.push(tag.text);
      }
      return <p>{l.join(", ")}</p>;
    }
    return (
      <ReactTags
        tags={questionsState[name].value}
        suggestions={suggestions[question.suggestionsName]}
        delimiters={delimiters}
        autocomplete={false}
        minQueryLength={1}
        handleDelete={(i) => {
          const newTags = questionsState[name].value.filter(
            (tag, name) => name !== i
          );
          updateState(name, "value", newTags);
          return newTags;
        }}
        handleAddition={(tag) => {
          const newTags = questionsState[name].value.concat([tag]);
          return updateState(name, "value", newTags);
        }}
        handleDrag={(tag, currPos, newPos) => {
          const newTags = questionsState[name].value.slice();
          newTags.splice(currPos, 1);
          newTags.splice(newPos, 0, tag);
          return newTags;
        }}
        handleTagClick={() => {
          console.log("click");
        }}
        inputFieldPosition="top"
        autocomplete
      />
    );
  };
  const CreateInput = (question, name) => {
    let propValue = {};
    let propReadOnly = [];
    if (question.value) {
      propValue = { value: question.value };
      propReadOnly = ["readOnly"];
    }
    console.log("ContactForm>CreateInput", propValue, propReadOnly);
    return (
      <>
        <Form.Control
          type={question.type}
          disabled={scopesStates[name + "scope"] === "2"}
          value={questionsState[name].value}
          onChange={(e) => {
            updateState(name, "value", e.target.value);
          }}
          {...propReadOnly}
          {...propValue}
        />
      </>
    );
  };

  const CreateCheckboxRadios = (question, name) => {
    console.log("ContactForm CreateCheckboxRadios", question);
    let propValue = {};
    let propReadOnly = [];
    console.log(
      "ContactForm>CreateChecboxRadio>",
      question,
      propValue,
      propReadOnly
    );
    let boxes = [];

    for (const [subName, box] of Object.entries(question.items)) {
      if (box.value) {
        propValue = { value: question.value };
        propReadOnly = ["readOnly"];
      }

      boxes.push(
        <Form.Check
          onChange={(e) => {
            console.log("ContactForm Check Value", e);
            setQuestionsState({
              ...questionsState,
              [name]: {
                ...questionsState[name],
                items: {
                  ...questionsState[name].items,
                  [subName]: {
                    ...questionsState[name].items[subName],
                    value: e.target.checked,
                  },
                },
              },
            });
          }}
          checked={questionsState[name].items[subName].value}
          type={question.type}
          label={box.label}
          name={name}
          disabled={scopesStates[name + "scope"] === "2"}
          {...propReadOnly}
          {...propValue}
        />
      );
    }
    return <div>{boxes}</div>;
  };

  const CreateDatePicker = (question, name) => {
    console.log("ContactForm create date picker");
    if (readOnly) {
      if (question.value === "") {
        return <p></p>;
      }
      const date = new Date(question.value * 1000);
      return <p>{date.toLocaleDateString()}</p>;
    }
    if (Number.isInteger(question.value)) {
      console.log("CreateDatePicker converting before date", question.value);
      question.value = new Date(question.value * 1000);
      console.log("CreateDatePicker converting after date", question.value);
    }
    return (
      <DatePicker
        onChange={(date) => {
          console.log("Datepicker", typeof date, date);
          updateState(name, "value", date);
        }}
        selected={questionsState[name].value}
        placeholderText="Select date"
        disabled={scopesStates[name + "scope"] === "2"}
      />
    );
  };

  const CreateRange = (question, name) => {
    if (readOnly) {
      return <p>{question.value}</p>;
    }

    if (question.props.max - question.props.min > 42) {
      return (
        <div>
          <Form.Control
            type="number"
            onChange={(e) => {
              updateState(name, "value", e.target.value);
            }}
            value={questionsState[name].value}
            min={question.props.min}
            max={question.props.max}
            disabled={scopesStates[name + "scope"] === "2"}
          />
        </div>
      );
    }

    return (
      <div>
        <Form.Range
          onChange={(e) => {
            updateState(name, "value", e.target.value);
          }}
          value={questionsState[name].value}
          min={question.props.min}
          max={question.props.max}
          disabled={scopesStates[name + "scope"] === "2"}
        />
        {questionsState[name] ? questionsState[name].value : ""}
      </div>
    );
  };

  const CreateQuestions = (questions, isOwner) => {
    const components = [];
    const scopesIcons = [<BellFill />, <Bell />, <BellSlash />];

    console.log("CreateQuestions", questions);
    for (const [name, question] of Object.entries(questions)) {
      console.log(
        "ContactForm CreateQuestions for>",
        profileState.edit,
        isOwner,
        name,
        question.state
      );
      if (profileState.edit === false && question.state == "discarded") {
        console.log("discarding", question.name);
        continue;
      }
      const component = CreateQuestion(question, name, isOwner);
      console.log(
        "ContactForm scopes",
        name,
        questionsState[name].state,
        scopesValues[questionsState[name].state]
      );
      components.push(
        <Form.Group as={Row} key={name} className="mb-3">
          <Col xs={2}>
            <Form.Label
              style={{
                color:
                  scopesStates[name + "scope"] === "2" ? "#6c757d" : "#000000",
              }}
            >
              {question.label}
            </Form.Label>
          </Col>
          <Col>
            {component}
            {question.comment && !readOnly && (
              <Form.Text className="text-muted">{question.comment}</Form.Text>
            )}
          </Col>
          <Col xs={1}>
            {profileState.edit == true && isOwner && (
              <div>
                <Form.Range
                  value={scopesValues[questionsState[name].state]}
                  onChange={(e) => {
                    updateState(name, "state", scopes[e.target.value]);
                  }}
                  min={0}
                  max={2}
                />
              </div>
            )}
          </Col>
          <Col xs={1}>
            {profileState.edit == true &&
              isOwner &&
              scopesIcons[
                scopesStates[name + "scope"]
                  ? scopesStates[name + "scope"]
                  : scopesValues[questionsState[name].state]
              ]}
          </Col>
        </Form.Group>
      );
    }
    console.log("returning components", components);
    return <>{components}</>;
  };

  const FormHolder = () => {
    return (
      <>
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" />
        <Placeholder as="p" animation="glow" style={{ width: "25%" }} />
      </>
    );
  };

  const CreateForm = (questions) => {
    if (questions === undefined) {
      return FormHolder();
    }
    if (questions === null) {
      return (
        <Alert avrient="info">
          Something somewhere went terribly wrong, we could find the profile you
          are looking for. May be they left?
        </Alert>
      );
    }

    return (
      <>
        <Row>This is the form the recruiters will fill for you.</Row>
        <Form noValidate validated={validated}>
          {CreateQuestions(questions, props.isOwner)}
          {SubmitComponent()}
        </Form>
      </>
    );
  };

  console.log("ContactForm reload", props);
  return <div className="mt-3">{CreateForm(questionsState)}</div>;
};

export default ContactForm;
