import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Auth } from "aws-amplify";
import { redirect } from "react-router-dom";

const Account = () => {
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");

  const submit = () => {
    const body = {
      function: "delete_profile",
      body: { email: email, description: description },
    };

    fetch(`/api/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log("resp>", response);
        return response.json();
      })
      .then((json) => {
        if (json.result) {
          alert(
            "Well it's done, you close close the tab and it's will be gone. You will be redirected in fe seconds"
          );
          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        } else {
          alert(
            "Sorry something went wrong, would you be so kind to drop us a mail"
          );
        }
      })
      .catch((err) => {
        alert(
          "Sorry something went wrong, would you be so kind to drop us a mail"
        );
      });
    Auth.signOut();
  };

  return (
    <Container>
      <Row>
        <h1>Account</h1>
      </Row>

      <Row className="mt-3">
        We are really sad to see you go, but just so you know, you can come back
        any time.
      </Row>
      <Row>
        We'd love to you know if there was any reason that is making you leave.
      </Row>
      <Row className="mt-3">
        <Col xs="auto">
          <Form.Label>Email:</Form.Label>
        </Col>
        <Col fluid>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Form.Text className="text-muted">
          Only if you want to discuss more.
        </Form.Text>
      </Row>
      <Row>
        <Form.Control
          as="textarea"
          rows={8}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Row>
      <Row>
        <Form.Text className="text-muted">
          Tell us what is on your heart.
        </Form.Text>
      </Row>

      <Row className="mt-3 mb-5">
        <Button variant="primary" onClick={submit}>
          Delete Account
        </Button>
      </Row>
    </Container>
  );
};

export default Account;
