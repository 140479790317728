import React, { useState, useEffect } from "react";
import Markdown from "marked-react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const DocMarkdown = (props) => {
  const [data, setData] = useState("");
  console.log("Building DocMarkdown", props.fileName);
  useEffect(() => {
    fetch(`/data/${props.fileName}.md`)
      .then((response) => response.blob())
      .then((blob) => blob.text())
      .then((text) => {
        setData(text);
      })
      .catch((err) => {
        console.log("md load", err);
      });
  }, []);

  return (
    <Container className="py-5">
      <Markdown>{data}</Markdown>
    </Container>
  );
};

export default DocMarkdown;
