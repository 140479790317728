import React, { useState, useEffect } from "react";
import { Amplify, Hub } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import {
  Button,
  Form,
  Modal,
  Spinner,
  NavDropdown,
  Nav,
  Link,
  Container,
} from "react-bootstrap";
import "@aws-amplify/ui-react/styles.css";
import { useSelector, useDispatch } from "react-redux";
import { actionLogin, actionLogout, loginStateGetter } from "./SliceLogin";
import { generateRandomString } from "./utils";
import { authModalStateGetter, actionShow, actionHide } from "./SliceAuthModal";
import { redirect } from "react-router-dom";
import { Linkedin } from "react-bootstrap-icons";

const Login = () => {
  const dispatch = useDispatch();
  const loginState = useSelector(loginStateGetter);
  const authModalState = useSelector(authModalStateGetter);

  const logout = () => {
    Auth.signOut()
      .then((data) => {
        console.log("Hub, active logout", data);
        dispatch(actionLogout());
        window.location.href = "/";
      })
      .catch((err) => console.log(err));
  };

  const getUsername = (user) => {
    try {
      const found = user.signInUserSession.idToken.payload.preferred_username;
      if (found) {
        return found;
      }
    } catch (e) {
      console.log("no prefered name for", user);
    }
    return user.username;
  };

  Hub.listen("auth", (data) => {
    console.log("Hub2 data", data);

    // Auth.currentSession()
    //   .then((foo) => {
    //     console.log("Hub32, currentSession", foo);
    //   })
    //   .catch((error) => {
    //     console.log("Hub32 error currentSession", error);
    //   });
    // Auth.currentUserInfo()
    //   .then((foo) => {
    //     console.log("Hub32, currentUserInfo", foo);
    //   })
    //   .catch((error) => {
    //     console.log("Hub32 error currentUserInfo", error);
    //   });
    // Auth.currentUserCredentials()
    //   .then((foo) => {
    //     console.log("Hub32, currentUserCred", foo);
    //   })
    //   .catch((error) => {
    //     console.log("Hub32 error currentUserCred", error);
    //   });
    // Auth.currentUserPoolUser()
    //   .then((foo) => {
    //     console.log("Hub32, currentUserPoolUser", foo);
    //   })
    //   .catch((error) => {
    //     console.log("Hub32 error curentUserPoolUser", error);
    //   });
    // Auth.userAttributes()
    //   .then((foo) => {
    //     console.log("Hub32, userAttributes", foo);
    //   })
    //   .catch((error) => {
    //     console.log("Hub32 error userAttributes", error);
    //   });
    console.log("Hub, check if user is auth");
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("Hub, currentAuthed", data.payload.event, user);

        dispatch(actionLogin(user));
        if (
          data.payload.event === "signIn" &&
          window.location.pathname == "/"
        ) {
          try {
            const profileId =
              user.signInUserSession.idToken.payload["custom:profileId"];
            if (profileId && profileId.length === 24) {
              console.log("moving");
              window.location.href = "/" + profileId;
            } else {
              console.log("bad profileid", profileId);
            }
          } catch (error) {
            console.log("error, missing porfileId in user", user);
          }
        }

        // if (data.payload.event === "signIn") {
        //   console.log (
        //     "Login, redirect",
        //     "/" + user.value.payload.attributes["custom:profileId"]
        //   );
        //   window.location =
        //     "/" + user.value.payload.attributes["custom:profileId"];
        // }
      })
      .catch((err) => {
        console.log("Hub, NOT Login, Current Auth", err);
        dispatch(actionLogout());
      });

    // switch (data.payload.event){
    //   case "signIn":
    //     Auth.currentAuthenticatedUser()
    //       .then((user) => {
    //         console.log("user already auth", user);
    //         setToken(user);
    //         dispatch(actionLogin(user));
    //       })
    //       .catch((err) => {
    //         console.log("Login, Current Auth", err);
    //       });

    //     console.log("user signed in");
    //     break;
    //   case "signUp":
    //     console.log("user signed up");
    //     break;
    //   case "signOut":
    //     console.log("user signed out");
    //     break;
    //   case "signIn_failure":
    //     console.log("user sign in failed");
    //     break;
    //   case "configured":
    //     console.log("the Auth module is configured");
    // }
  });
  const unauthorizedComponent = () => {
    return (
      <Button
        variant="outline-success"
        id="login-button"
        onClick={() => {
          dispatch(actionShow());
        }}
      >
        Login/SingUp
      </Button>
    );
  };
  const authorizedComponent = (user) => {
    if (user.state !== "connected") {
      return unauthorizedComponent();
    }
    console.log("authorizedComponent user", user);
    return (
      <NavDropdown
        title={getUsername(user.value.payload)}
        id="basic-nav-dropdown"
      >
        <NavDropdown.Item
          href={
            "/" +
            user.value.payload.signInUserSession.idToken.payload[
              "custom:profileId"
            ]
          }
        >
          Profile
        </NavDropdown.Item>
        <NavDropdown.Item href="/responses">Responses</NavDropdown.Item>
        <NavDropdown.Item href="/account">Account</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
      </NavDropdown>
    );
  };

  const [configured, setConfigured] = useState(false);

  useEffect(() => {
    console.log("useEffect Login");
    fetch("/static/cognito.json", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Login redirect", `https://${window.location.hostname}`);
        json.Auth = {
          oauth: {
            domain: json.auth_domain,
            scope: ["openid", "profile", "email"],
            redirectSignIn: `https://${window.location.hostname}`,
            redirectSignOut: `https://${window.location.hostname}`,
            responseType: "code",
            clientId: json.aws_user_pools_web_client_id,
          },
          cookieStorage: {
            path: "/",
            expires: 365,
            sameSite: "strict",
            secure: true,
          },
        };

        console.log("Auth.configure", json);

        Auth.configure(json);
        setConfigured(true);
      });
  }, []);

  return (
    <>
      {authorizedComponent(loginState)}
      <Modal
        show={authModalState}
        onHide={() => {
          dispatch(actionHide());
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {configured ? (
            <Authenticator
              loginMechanisms={["username", "email"]}
              onStateChange={(authState) =>
                console.log("*************new state", authState)
              }
              components={{
                SignIn: {
                  Header() {
                    return (
                      <>
                        <Container className="px-4 my-3" fluid>
                          <Button
                            onClick={() => {
                              Auth.federatedSignIn({ provider: "linkedin" });
                            }}
                            className="justify-content-md-center"
                            variant="outline-primary"
                            className="w-100"
                          >
                            <Linkedin />
                          </Button>
                        </Container>
                      </>
                    );
                  },
                },
                SignUp: {
                  FormFields() {
                    const { validationErrors } = useAuthenticator();

                    return (
                      <>
                        <Button
                          onClick={() => {
                            Auth.federatedSignIn({ provider: "linkedin" });
                          }}
                          className="justify-content-md-center"
                          variant="outline-primary"
                        >
                          <Linkedin />
                        </Button>
                        <Authenticator.SignUp.FormFields />

                        <Form.Check
                          name="terms"
                          id="terms"
                          label=<a href="/terms-of-service">
                            I agree with the terms and conditions
                          </a>
                          feedback="You must agree before submitting."
                          feedbackType="invalid"
                        />
                      </>
                    );
                  },
                },
              }}
              services={{
                async validateCustomSignUp(formData) {
                  console.log("custom validation", formData);
                  if (formData.terms != "on") {
                    return {
                      acknowledgement:
                        "You must agree to the Terms & Conditions",
                    };
                  }
                },
              }}
            >
              {({ signOut, user }) => {
                console.log("Authenticator ok", user);
                dispatch(actionHide());
              }}
            </Authenticator>
          ) : (
            <p>
              <Spinner animation="border" variant="primary" />
              Loading...
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Login;
