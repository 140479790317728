import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { loginStateGetter } from "./SliceLogin";
import { useSelector } from "react-redux";

const Feedback = (props) => {
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState("?");
  const [description, setDescription] = useState("");
  const [sending, setSending] = useState(false);
  const loginState = useSelector(loginStateGetter);

  const [happiness, setHappiness] = useState("");
  const sendFeedback = () => {
    setSending(true);
    const body = {
      function: "feedback",
      body: { description: description, happiness: happiness },
    };

    fetch(`/api/feedback`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log("resp>", response);
        return response.json();
      })
      .then((json) => {
        setSending(false);
      })
      .catch((err) => {
        alert("Something, somewhere, went terribly wrong");
        setSending(false);
      });
  };

  if (loginState.state !== "connected") {
    return <></>;
  }

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => {
          setShow(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Tell us everything</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Control
            rows={8}
            as="textarea"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />

          <Row className="mt-3">
            <Col className="text-center">
              <Form.Check
                onChange={(e) => {
                  setHappiness(e.target.name);
                }}
                name="happy"
                type="radio"
              ></Form.Check>
            </Col>
            <Col className="text-center">
              <Form.Check
                onChange={(e) => {
                  setHappiness(e.target.name);
                }}
                name="ok"
                type="radio"
              ></Form.Check>
            </Col>
            <Col className="text-center">
              <Form.Check
                onChange={(e) => {
                  setHappiness(e.target.name);
                }}
                name="mad"
                type="radio"
              ></Form.Check>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">😍</Col>
            <Col className="text-center">😉</Col>
            <Col className="text-center">😠</Col>
          </Row>
          <Row className="mt-3">
            <Button
              variant="primary"
              disabled={sending}
              onClick={() => {
                sendFeedback();
              }}
            >
              {!sending ? "Send" : "Calling help desk"}
            </Button>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Button
        style={{
          position: "fixed",
          right: "0",
          top: "90%",
        }}
        variant="outline-success"
        onMouseEnter={() => {
          setLabel("Feedback");
        }}
        onMouseLeave={() => {
          setLabel("?");
        }}
        onClick={() => {
          setShow(true);
        }}
      >
        {label}
      </Button>
    </>
  );
};

export default Feedback;
